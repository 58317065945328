<template>
  <div class="resultContainer">
    <div class="wishContainer p-component-overlay p-dialog-mask" v-if="!rollDone">
      <div class="wishContainerLoading" v-if="!rollReady">
        <h2>A teraz słowo od naszego sponsora, RA...</h2>
        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
        <h5>Z powodów prawnych musimy napisać, że jest to żart.</h5>
      </div>
      <div class="wishContainerVideo" v-show="rollReady">
        <video autoplay muted id="wishVideo" ref="wishVideo">
          <source :src="'videos/' + $store.state.latestReward.rarity + 'starwish-single.mp4'" type="video/mp4">
        </video>
      </div>
    </div>
    <div class="resultScreen" v-if="rollDone">
      <Divider />
      <h2>Twoja nagroda to:</h2>
      <Divider />
      <Chip>
        <h3>
          {{ $store.state.latestReward.rewardName }}
        </h3>
      </Chip>
      <Divider />
      <img style="max-width: 100%;" :src="$store.state.latestReward.rewardImage">
      <Divider />
      <p>Rozdanie nagród odbędzie się:</p>
        <Chip class="claimInfoContainer" v-if="claimInfo.date != undefined">
          {{ parseDate(claimInfo.date) }} {{ claimInfo.location }}
          {{ claimInfoError }}
        </Chip>
      <p>Pamiętaj, żeby przynieść swój ID!</p>
      <p>Swoje wszystkie nagrody możesz sprawdzić również w zakładce "Twój Profil".</p>
      <Button @click="$router.push('/')">Powrót do strony głównej</Button>
    </div>

  </div>
</template>

<script>
import apiCalls from '@/services/apiCalls.service.js';
import Button from "primevue/button";
import Divider from 'primevue/divider';
import Chip from 'primevue/chip';

export default {
  data() {
    return {
      rollReady: false,
      rollDone: false,
      claimInfo: {},
      claimInfoError: ""
    }
  },
  beforeMount(){
    this.prepare()
    this.fetchClaimInfo()
  },
  mounted(){
    this.preloadVideoHandler()
},
  methods: {
    prepare(){
      if (this.$store.state.latestReward == {}){
        this.$router.push("/")
        alert("Nie masz jeszcze żadnych nagród do odebrania.")
      } else {}

    },
    preloadVideoHandler(){
      var self = this
      this.$refs.wishVideo.oncanplaythrough = function() {
      self.rollReady = true

      setTimeout(() => {
        self.rollDone = true
      }, 7100);
      }
    },
    fetchClaimInfo(){
      apiCalls.get("claim-info/")
        .then(res => this.claimInfo = res)
        .catch(err => {
          console.log(err)
          this.claimInfoError = "Coś poszło nie tak, spróbuj ponownie później."
        })
    },
    parseDate(dateString){
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('pl-PL', { weekday: "long", hour: "numeric", minute: "numeric" }).format(date);
    }
  },
  components: {
    Button,
    Divider,
    Chip
  }
}
</script>

<style lang="scss" scoped>
.resultScreen {
  margin: 1rem;
}
</style>